<template>
    <div>
      <b-modal :id="$route.name + 'CreateModal'" title="Добавить тип фотоконтроля" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData" > 
            <b-row class="mt-1">
                <b-col cols="12">
                    <label for="name">Наименование</label>
                    <b-input v-model="bodyType.name" placeholder="Наименование" id="name" />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12">
                    <label for="alias_for_file_name">Псевдоним для имени файла</label>
                    <b-input v-model="bodyType.alias_for_file_name" placeholder="Псевдоним для имени файла" id="alias_for_file_name" />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12">
                    <label for="description">Описание</label>
                    <b-input v-model="bodyType.description" placeholder="Описание" id="description" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    component: {
        ToastificationContent,
    },
    props: ['categories' , 'id'],
    data(){
        return {
            bodyType: {
                name: null,
                description: null,
                alias_for_file_name: null,
                id: true,
            },
            submitButtonDisabled: false,
            categoriesSelectDisabled: false,
        }
    },
    methods: {
        clearData(){
            this.bodyType = {
                name: null,
                description: null,
                alias_for_file_name: null,
                id: true,
            }
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        createBodyType() {
            this.submitButtonDisabled = true
            this.$http.post('/photo-control/type', this.bodyType)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип фотоконтроля создан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        
    }
}
</script>