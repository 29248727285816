<template>
    <div>
        <b-modal id="PhotocontrolTypeEdit" 
         @shown="getData()"
         title="Редактирование типа фотоконтроля" 
         size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" >
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="name">Наименование</label>
                    <b-input type="text" v-model="bodyType.name" placeholder="Наименование" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="alias_for_file_name">Псевдоним для имени файла</label>
                    <b-input type="text" v-model="bodyType.alias_for_file_name" placeholder="Псевдоним для имени файла" />
                </b-col>
            </b-row >
            <b-row class="mb-1">
            <b-col cols="12">
                    <label for="description">Описание</label>
                    <b-textarea type="text" v-model="bodyType.description" placeholder="Описание"  />
                </b-col>
            </b-row >    
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    class="mt-2 col-md-5"
                    variant="primary"
                    :disabled="submitButtonDisabled"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
    },
    props: ['id'],
    data(){
        return {
            bodyType: {
                name: null,
                alias_for_file_name: null,
                description:null,
            },
            submitButtonDisabled: false,
        }
    },
    methods: {
        clearData() {
            this.bodyType = {
                name: '',
                alias_for_file_name: '',
                description: '',
            }
        },
        getData(){
            console.log(this.id);
            this.$http.get(`/photo-control/type/${this.id}/edit`)
            .then(res => {
                this.bodyType = res.data
                this.$bvModal.show('PhotocontrolTypeEdit')
            })
        },
        closeModal() {
            this.$bvModal.hide('PhotocontrolTypeEdit')
        },
        editBodyType() {
            this.submitButtonDisabled = true
            this.$http.patch(`/photo-control/type/${this.id}`, this.bodyType)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип фотоконтроля изменен!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal();
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = true
            })
        },
    },
}
</script> 

