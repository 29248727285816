<template>
    <div>
      <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
      <div v-else>
        <table-photocontrol-type :options="bodyTypes" @editForm="editForm" @refresh="refresh"/>
      </div>
      <modal-photocontrol-type  @refresh="refresh" />
      <modal-photocontrol-type-edit :id="id"  @refresh="refresh" />
    </div>
  </template>
  <script>
  import ModalPhotocontrolType from '@/views/component/Modal/ModalPhotocontrolType/ModalPhotocontrolType.vue'
  import ModalPhotocontrolTypeEdit from '@/views/component/Modal/ModalPhotocontrolType/ModalPhotocontrolTypeEdit.vue'
  import TablePhotocontrolType from '@/views/component/Table/tablePhotocontrolType.vue'
  export default {
    components: {
        ModalPhotocontrolType,
        ModalPhotocontrolTypeEdit,
        TablePhotocontrolType,
    },
    data() {
      return {
        bodyTypes: [],
        showPreloader: false,
        selectedBodyType: null,
        id: null
      }
    },
    mounted(){
      this.$store.commit('pageData/setdataCount', null)
      this.refresh()
    },
    methods: {
      editForm(id) {
        this.$bvModal.show(`PhotocontrolTypeEdit`)
        this.id = id
      },
      refresh(){
        this.showPreloader = true
        this.$http
        .get('photo-control/type')
        .then(res => {
          this.bodyTypes = res.data
          this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
          this.showPreloader = false
          this.$store.commit('REFRESH_DATA', false)
        }).catch(err => {})
      },
      sendToParent(tableData){
        this.bodyTypes = tableData
        this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
      },
    },
    computed: {
      fetchingNewData(){
        return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val){
        if (val){
          let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
          this.$http
          .get(`${this.$route.name}`, {params: params})
          .then(res =>{
            this.bodyTypes = res.data
            this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
            this.$store.commit('REFRESH_DATA', false)
          })
        }
      }
    }
  }
  </script>